<template>
  <FormCard @click:cancel="onCancel" @click:save="onSave">
    <template #content>
      <div class="grid grid-cols-2 gap-x-8 gap-y-2">
        <VText
          v-model="v.name.$model"
          :label="$t('app.name')"
          class="col-span-2"
          :errors="v.name.$errors"
          @keyup.enter="onSave"
        />

        <VText
          v-model="v.code.$model"
          :label="$t('app.code')"
          :errors="v.code.$errors"
          @keyup.enter="onSave"
        >
          <template #hint>
            {{ $t("hints.administration_departments_code") }}
          </template>
        </VText>

        <VText
          v-model="v.external_code.$model"
          :label="$t('app.external_code')"
          :errors="v.external_code.$errors"
          @keyup.enter="onSave"
        >
          <template #hint>
            {{ $t("hints.administration_departments_external_code") }}
          </template>
        </VText>

        <VWysiwyg
          v-model="v.details.$model"
          :label="$t('app.details')"
          :errors="v.details.$errors"
          class="col-span-2"
        />

        <VSelect
          v-model="v.department_id.$model"
          :label="$t('app.parent_department')"
          :errors="v.department_id.$errors"
          :options="departmentOptions"
          class="col-span-2"
          @keyup.enter="onSave"
        >
          <template #hint>
            {{ $t("hints.administration_departments_parent_department") }}
          </template>
        </VSelect>

        <VColor
          v-model="v.colour.$model"
          :label="$t('app.colour')"
          :errors="v.colour.$errors"
          @keyup.enter="onSave"
        />

        <VSwitch
          v-model="v.status.$model"
          :label="$t('app.status')"
          true-value="Active"
          false-value="Inactive"
          :errors="v.status.$errors"
        />
      </div>
    </template>
  </FormCard>
</template>

<script>
import { onMounted, computed } from "vue";
// Composables
import useForm from "@/composables/useForm";
import useDepartment from "@/composables/useDepartment";
// Mixins
import FormMixin from "@/mixins/FormMixin.js";

export default {
  mixins: [FormMixin],
  setup(props, context) {
    const { value, rules, options, getOptions } = useDepartment();
    const { v, onSave, onCancel, isCreate } = useForm(props, context, {
      value,
      rules
    });

    // Computed
    const departmentOptions = computed(() => {
      return options.value.department_id.map(option => ({
        ...option,
        disabled: isDisabled(option)
      }));
    });

    // Methods
    const isDisabled = department => {
      if (isCreate.value) {
        return false;
      }

      const isSelf = department.id === props.data.id;
      return isSelf || isChildDepartment(department, props.data.id);
    };

    onMounted(async () => {
      await getOptions();
    });

    const isChildDepartment = department => {
      if (department?.department_id === props.data.id) {
        return true;
      } else if (department?.department_id) {
        const parent = options.value.department_id.find(option => {
          return option.id === department.department_id;
        });

        if (!parent) {
          return false;
        }

        return isChildDepartment(parent);
      }

      return false;
    };

    return {
      departmentOptions,
      // useDepartment
      value,
      options,
      // useForm
      v,
      onSave,
      onCancel
    };
  }
};
</script>
