<template>
  <div>
    <VTitle :title="$t('app.edit_department')" class="section-title" />

    <div class="grid responsive-cols gap-8 mt-10">
      <div class="flex flex-col">
        <Form
          class="z-10"
          :is-loading="isLoading"
          :data="data"
          @click:cancel="onClickCancel"
          @click:save="onClickSave"
        />

        <Logs :data="data" class="mt-8 z-0" @click="onClickLogs" />
      </div>

      <div>
        <ResourceUsers
          :id="id"
          :endpoint="endpoint"
          :title="$t('app.department_users')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed } from "vue";
// Composables
import useUpdate from "@/composables/useUpdate";
import useDepartment from "@/composables/useDepartment";
// Components
import ResourceUsers from "@/components/templates/ResourceUsers";
import VTitle from "@/components/VTitle";
import Logs from "@/components/Logs";
import Form from "./Form";

export default {
  components: {
    VTitle,
    Form,
    ResourceUsers,
    Logs
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();

    // COMPUTED
    const documentTitle = computed(() => {
      const title = `${t("app.departments", 2)} - ${t("app.administration")}`;

      const name = data?.value?.name;
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // CUSTOM COMPOSABLES
    const { logType, endpoint, route } = useDepartment();
    const {
      data,
      isLoading,
      getData,
      onClickSave,
      onClickCancel,
      onClickLogs,
      onClickCreate
    } = useUpdate({
      logType,
      endpoint,
      route,
      id: props.id,
      relations: []
    });

    return {
      documentTitle,
      // useDepartment
      endpoint,
      route,
      logType,
      // useUpdate
      data,
      isLoading,
      getData,
      onClickSave,
      onClickCancel,
      onClickLogs,
      onClickCreate
    };
  }
};
</script>
