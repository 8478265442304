import { onMounted } from "vue";
// Composables
import useOptions from "@/composables/useOptions";
import useUser from "@/composables/useUser";
import useRequest from "@/composables/useRequest";
import useReadMultiple from "@/composables/useReadMultiple";
import usePaginate from "@/composables/usePaginate";

export default function useEntityUsers(props, args) {
  const { endpoint } = args;

  // Composables
  const { request } = useRequest();
  const { endpoint: userEndpoint, searchFields } = useUser();
  const { isRequesting: isAdding, request: add } = useRequest();
  const { data: rows, pagination, isLoading, read } = useReadMultiple();
  const { loadOptions, formatOptions, ALL_OPTIONS } = useOptions();

  // METHODS
  const getData = async () => {
    await read({
      endpoint: `${endpoint}.users`,
      pathParams: {
        id: props.id
      }
    });
  };

  const onClickDelete = async item => {
    await request({
      endpoint: `${endpoint}.users.delete`,
      pathParams: {
        id: props.id,
        user_id: item.id
      }
    });

    await getData();
  };

  const onClickAddUser = async id => {
    await add({
      endpoint: `${endpoint}.users.create`,
      pathParams: {
        id: props.id
      },
      data: {
        user_id: id
      }
    });

    await getData();
  };

  const load = query => {
    const filters = [
      {
        f: searchFields,
        c: "LIKE",
        v: [query]
      },
      {
        f: "status",
        c: "LIKE",
        v: [ALL_OPTIONS.ACTIVE.value]
      }
    ];

    return loadOptions(userEndpoint, {
      ro_f: encodeURIComponent(JSON.stringify(filters))
    });
  };

  const formatLoadOptions = options => {
    const formatText = option => {
      return `${option.firstname} ${option.lastname} - ${option.username}`;
    };

    return formatOptions(options, { formatText });
  };

  // LIFECYCLE HOOKS
  onMounted(async () => {
    await getData();
  });

  // eslint-disable-next-line
  const { onUpdateCurrentPage, onUpdatePerPage } = usePaginate(pagination, getData);

  return {
    getData,
    load,
    formatLoadOptions,
    onClickAddUser,
    onClickDelete,
    // useRequest
    isAdding,
    // usePaginate
    onUpdateCurrentPage,
    onUpdatePerPage,
    // useReadMultiple
    rows,
    pagination,
    isLoading
  };
}
