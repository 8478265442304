<template>
  <TableCard
    display-order
    display-pagination
    :headers="headers"
    :rows="rows"
    :is-loading="isLoading"
    :pagination="pagination"
    @update:current_page="onUpdateCurrentPage"
  >
    <template #title>
      <div class="capitalize">
        {{ title }}
      </div>
    </template>

    <template #title-actions>
      <VSearchResource
        :load="load"
        :disabled="disabled"
        :format-options="formatLoadOptions"
        @add="onClickAddUser"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.__ACTIONS="{ item }">
      <div class="flex justify-end">
        <VDeleteAction
          :disabled="disabled"
          :item="item"
          :text-value="`${item.firstname} ${item.lastname}`"
          :delete-function="onClickDelete"
        />
      </div>
    </template>
  </TableCard>
</template>

<script>
import { useI18n } from "vue-i18n";
// Composables
import useEntityUsers from "@/composables/useEntityUsers";
// Components
import TableCard from "@/components/tables/TableCard.vue";
import VDeleteAction from "@/components/VDeleteAction";
import VSearchResource from "@/components/inputs/VSearchResource";

export default {
  components: {
    TableCard,
    VDeleteAction,
    VSearchResource
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    endpoint: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();

    // CONSTANTS
    const headers = [
      {
        text: t("app.firstname"),
        value: "firstname"
      },
      {
        text: t("app.lastname"),
        value: "lastname"
      },
      {
        text: t("app.username"),
        value: "username"
      },
      {
        value: "__ACTIONS"
      }
    ];

    // CUSTOM COMPOSABLES
    const {
      onClickAddUser,
      onClickDelete,
      load,
      formatLoadOptions,
      getData,
      isAdding,
      rows,
      isLoading,
      pagination,
      onUpdateCurrentPage
    } = useEntityUsers(props, { endpoint: props.endpoint });

    return {
      headers,
      // useEntityUsers
      pagination,
      onUpdateCurrentPage,
      load,
      onClickAddUser,
      onClickDelete,
      getData,
      isAdding,
      formatLoadOptions,
      rows,
      isLoading
    };
  }
};
</script>
